import React from 'react'
import MyAccount from '../components/MyAccount'

function ViewMyAccount() {
	return (
		<div className="ViewMyAccount pt-5">
			<MyAccount />
		</div>
	)
}

export default ViewMyAccount