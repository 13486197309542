import React from 'react'

// import Menu from '../components/Menu'
// import Searchbar from '../components/Searchbar'

function ViewContact() {
	return (
		<div className="Contact">
			<p>Contact</p>
		</div>
	)
}

export default ViewContact