import React from 'react'
import LoginForm from '../components/LoginForm'

function ViewLogin() {
	return (
		<div className="ViewLogin pt-5">
			<LoginForm />
		</div>
	)
}

export default ViewLogin