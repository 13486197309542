import React from 'react'
import RegisterForm from '../components/RegisterForm'

function ViewRegister() {
	return (
		<div className="ViewRegister pt-5">
			<RegisterForm />
		</div>
	)
}

export default ViewRegister