import React from 'react'
import Chat from '../components/Chat'

// import Menu from '../components/Menu'
// import Searchbar from '../components/Searchbar'

function ViewChat() {
	return (
		<div className="ViewChat">
			<Chat />
		</div>
	)
}

export default ViewChat